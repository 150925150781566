import { useEffect, useRef } from "react";

import { HorizontalFlexContainer } from "../../components/Layout/HorizontalFlexContainer";
import MainSectionContainer from "../../components/Layout/MainSectionContainer";
import { VerticalFlexContainer } from "../../components/Layout/VerticalFlexContainer";
import "./Banner.css";
import Spacer from "../../components/Layout/Spacer";
import Cover from "../../Assets/cover.png";
import TelegramIcon from "../../components/Icons/TelegramIcon";
import FacebookIcon from "../../components/Icons/FacebookIcon";
import InstagramIcon from "../../components/Icons/InstagramIcon";
import RedditIcon from "../../components/Icons/RedditIcon";
import TwitterIcon from "../../components/Icons/TwitterIcon";
import DiscordIcon from "../../components/Icons/DiscordIcon";
import MediumIcon from "../../components/Icons/MediumIcon";

const socialMedia = [
  {
    link: "https://t.me/ASSGardiansCommunity",
    logo: <TelegramIcon color="white" size={30} />,
  },
  {
    link: "https://www.facebook.com/Assgard-Token-106104181649917",
    logo: <FacebookIcon color="white" size={30} />,
  },
  {
    link: "https://www.instagram.com/assgard.token/",
    logo: <InstagramIcon color="white" size={30} />,
  },
  {
    link: "https://www.reddit.com/r/AssgardToken/",
    logo: <RedditIcon color="white" size={30} />,
  },
  {
    link: "https://twitter.com/ASSGARDToken",
    logo: <TwitterIcon color="white" size={30} />,
  },
  {
    link: "https://discord.gg/cTBjxqFd6K",
    logo: <DiscordIcon color="white" size={30} />,
  },
  {
    link: "https://assgardtoken.medium.com/",
    logo: <MediumIcon color="white" size={30} />,
  },
];

function Icons() {
  return (
    <HorizontalFlexContainer width="100%" justify="flex-start">
      {socialMedia.map((val, i) => (
        <div
          className="icon"
          key={i}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(val.link);
          }}
          style={{
            zIndex: 1000,
          }}
        >
          {val.logo}
        </div>
      ))}
    </HorizontalFlexContainer>
  );
}
export default function Banner() {
  return (
    <>
      {/* <div
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          zIndex: 0,
          backgroundImage: `url(${Cover})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      /> */}
      <MainSectionContainer backgroundColor="transparent" id="Home">
        <div className="banner-inner-container">
          <VerticalFlexContainer justify="flex-start" align="flex-start">
            <div className="title-container">
              <h1 id="main-title">Welcome</h1>
              <h1 id="main-title">Assgardians</h1>
              <h3 id="sub-title">We Gard Your Ass...ets!</h3>
              <Spacer height="32px" />
              <Icons />
            </div>
          </VerticalFlexContainer>
        </div>
      </MainSectionContainer>
    </>
  );
}
