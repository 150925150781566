import { HorizontalFlexContainer } from "../../components/Layout/HorizontalFlexContainer";
import MainSectionContainer from "../../components/Layout/MainSectionContainer";
import { VerticalFlexContainer } from "../../components/Layout/VerticalFlexContainer";
import Spacer from "../../components/Layout/Spacer";
import BaseText from "../../components/Text/BaseText";
import Iframe from "react-iframe";
import { useState, useEffect } from 'react';
import Grid from '@react-css/grid';
import "./Vision.css";

export default function Vision() {
  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);
  return (
    <MainSectionContainer paddingHorizontal="10vw" height="auto" backgroundColor="transparent">
       <BaseText
        color="#DEDEDE"
        fontSize="21px"
        fontWeight="bold"
        textAlign="center"
        id="Vision"
      >
        The Future Of Assgard
      </BaseText>

       <BaseText
        color="#DEDEDE"
        fontSize="42px"
        fontWeight="bold"
        textAlign="center"
      >
        Vision
      </BaseText>
      <Spacer height="40px" />
      <HorizontalFlexContainer justify="center">
        <VerticalFlexContainer align="center">
          <div>
            <Grid autoFlow='column' columnGap='20px'>
              <button className="button"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href="/ASSG_WHITEPAPER.pdf";
                    }}
              > Assgard Whitepaper</button>
              {/* <embed src="/Assets/ASSG_WHITEPAPER.pdf#page=1" type="application/pdf" width="100%" height="100%"></embed> */}
              {/* > Operation Mirror Mirror<br></br>Coming Soon</button> */}
              {/* <button className="button"
                  type="button"
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   window.location.href='http://google.com';
                  //   }}
              > Operation BiFrost<br></br>Coming Soon</button> */}
            </Grid>
          </div>
        </VerticalFlexContainer>
        </HorizontalFlexContainer>
        <Spacer backgroundColor="transparent" height="100px" />
    </MainSectionContainer>
    );
}