import {useEffect, useState} from "react";
import MainSectionContainer from "../../components/Layout/MainSectionContainer";
import BaseText from "../../components/Text/BaseText";
import FeatureSelector from "./FeatureSelector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faFlask,
  faPiggyBank,
} from "@fortawesome/free-solid-svg-icons";
import {HorizontalFlexContainer} from "../../components/Layout/HorizontalFlexContainer";
import Spacer from "../../components/Layout/Spacer";
import {VerticalFlexContainer} from "../../components/Layout/VerticalFlexContainer";

const options = {
  "Community Driven": {
    title: "Driven",
    description: (
      <div className="description-container">
        <h2 className="token-title">The Plan</h2>
        <p className="token-text">
          Assgard is dedicated to making a suite of products that will make the 
          crypto space a much safer place and provide its users with the ability 
          to automate how they interact with the block chain. We have already 
          released SafeGard Suite™.  We are working on LaunchGard Suite™ and 
          SafeGard Suite™ Mobile.
        </p>
        <p className="token-text">
          Assgard is creating the most comprehensive portfolio tracker 
          (SafeGard Suite™) currently available for BEP20 and ERC20 Assets.  
          There are other trackers out there but all the ones that we have 
          tested are lacking and we will to fix this! 
        </p>
        <p className="token-text">
          SafeGard Suite™ will offer new and innovative ways to track your crypto 
          assets. We are changing how people trade on the Block Chain. Alerts. 
          Charts. Data analytics. Value tracking across all tokens in your wallet. 
          One place for all your data needs.
        </p>
        <p className="token-text">
          LaunchGard Suite™ will be a safer place to launch tokens on the BSC network. 
          Investors will be able to use LaunchGard Suite™ to research the risk level 
          of new tokens. Token creators will be able to launch on our platform after 
          being thoroughly evaluated. LaunchGard Suite™ will connect selective investors 
          with great projects.
        </p>
        <p className="token-text">
          SafeGard Suite™ Mobile will provide the same information as SafeGard Suite™ 
          but in a mobile platform that will work on both Android and IOS devices.
        </p>
        <p className="token-text">We Gard Your Ass...ets!</p>
      </div>
    ),
    icon: <FontAwesomeIcon color="#222" icon={faUsers} size="3x"/>,
  },
  "Tokenomics BSC": {
    title: "Tokenomics BSC",
    description: (
      <div className="description-container">
        <h2 className="token-title">8% Automatic LP ♻️</h2>
        <p className="token-text">
          Every trade contributes towards automatically generating liquidity
          locked inside PancakeSwap LP, this adds value to the token as the
          community grows. The value the creator chose is 8% for this LP
          contribution.
        </p>
        <h2 className="token-title">2% Reflection 💎</h2>
        <p className="token-text">
          Holders earn passive rewards through static reflection as they watch
          their balance of Assgard tokens grow indefinitely. 2% of every trade
          will be distibuted accross all holders.
        </p>
        <h2 className="token-title">Project underway 🙌</h2>
        <p className="token-text">
          As a community we will decide what the utility of this token is. We
          already have several proposals for real life projects, such as the
          profit tracker. A team has already formed and is ready to take up the
          project.
        </p>
      </div>
    ),
    icon: <FontAwesomeIcon color="#222" icon={faPiggyBank} size="3x"/>,
  },

  "Tokenomics ETH": {
    title: "Tokenomics ETH",
    description: (
      <div className="description-container">
        <h2 className="token-title">6% Automatic LP ♻️</h2>
        <p className="token-text">
          Every trade contributes towards automatically generating liquidity
          locked inside PancakeSwap LP, this adds value to the token as the
          community grows. The value the creator chose is 6% for this LP
          contribution.
        </p>
        <h2 className="token-title">5% Redistribution 💎</h2>
        <p className="token-text">
          Holders earn passive rewards through static reflection as they watch
          their balance of Assgard token grow indefinitely. 5% of every trade
          will be distibuted accross all holders.
        </p>
        <h2 className="token-title">2% Marketing 📜</h2>
        <p className="token-text">
          In order to grow the Assgard name we must advertise the great work
          being done on this project. Unfortunately advertising isn't free. In
          order to pay for this 2% of every trade will go to the marketing
          wallet.
        </p>
        <h2 className="token-title">3% Development 💻</h2>
        <p className="token-text">
          There's not much point to advertise if you don't have great software
          products. In order to continue developing all the features our
          community demands, 3% of every trade will go to the development
          wallet.
        </p>
      </div>
    ),
    icon: <FontAwesomeIcon color="#222" icon={faPiggyBank} size="3x"/>,
  },
  Features: {
    title: "Features",
    description: (
      <div className="description-container">
        <h2 className="token-title">🔥 LP Burned 🔥</h2>
        <p className="token-text">
          The LP is burned. You don't have to trust our word for it, check
          the&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href="https://bscscan.com/tx/0x894f71941715a7324b663ab9c4156bad0d62d9875ae75975f8cbc4d6773c5f52"
          >
            Bscscan transaction
          </a>
          &nbsp;out for yourself!
        </p>

        <h2 className="token-title">👌🏻 Ownership Not Renounced 👌🏻</h2>
        <p className="token-text">
          The owners of Assgard have big plans for this project. Once an
          assgardian, always an assgardian. We will need control to grow this
          project to the table of the gods. 
          {/* research, here's the&nbsp;
          <a
            target="_blank"
            href="https://bscscan.com/tx/0x372078ef0c811cc9fcdbbe89b1dde3dfb8510bdb4e6e190a37bc388767ac5004"
          >
            transaction
          </a>
          . */}
        </p>
        <h2 className="token-title">🔰 Stealth Launch 🔰</h2>
        <p className="token-text">
          No-one saw it coming. The token was released completely undercover and
          left in the fate of the bums of the gods.
        </p>
      </div>
    ),
    icon: <FontAwesomeIcon color="#222" icon={faFlask} size="3x"/>,
  },
};

export default function Token() {
  const [option, setOption] = useState("Tokenomics BSC");

  return (
    <MainSectionContainer
      paddingHorizontal="10vw"
      backgroundColor="transparent"
      height="auto"
      width="100%"
      id="Token"
    >
      <Spacer height="40px"/>
      <BaseText
        color="#DEDEDE"
        fontSize="42px"
        fontWeight="bold"
        textAlign="left"
      >
        Token ($ASSG)
      </BaseText>
      <Spacer height="12px"/>
      <h3 fontSize="24px" id="token-address">
        0x7c52e19e4d350189bcdfb7f7a0868117e9f195e5
      </h3>
      <Spacer height="40px"/>
      <FeatureSelector
        options={Object.keys(options)}
        setOption={setOption}
        currentOption={option}
      />
      <HorizontalFlexContainer width="100%" flexWrap="wrap">
        <VerticalFlexContainer flex={1} justify="center" align="center">
          <Spacer flex={1}/>
          <div className="icon-container">{options[option].icon}</div>
          <Spacer flex={3}/>
        </VerticalFlexContainer>
        <HorizontalFlexContainer flex={1} height="100%">
          {options[option].description}
        </HorizontalFlexContainer>
      </HorizontalFlexContainer>
      <Spacer backgroundColor="transparent" height="100px"/>
    </MainSectionContainer>
  );
}
