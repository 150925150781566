import { HorizontalFlexContainer } from "../../components/Layout/HorizontalFlexContainer";
import MainSectionContainer from "../../components/Layout/MainSectionContainer";
import { VerticalFlexContainer } from "../../components/Layout/VerticalFlexContainer";
import Spacer from "../../components/Layout/Spacer";
import BaseText from "../../components/Text/BaseText";
import Iframe from "react-iframe";
import { useState, useEffect } from 'react';

export default function HowToBuy() {
  const hasWindow = typeof window !== 'undefined';

  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);
  return (
    <MainSectionContainer paddingHorizontal="10vw" height="auto" backgroundColor="transparent">
       <BaseText
        color="#DEDEDE"
        fontSize="42px"
        fontWeight="bold"
        textAlign="center"
        id="HowToBuy"
      >
        How To Buy Assgard Token
      </BaseText>

       <BaseText
        color="#DEDEDE"
        fontSize="21px"
        fontWeight="bold"
        textAlign="center"
      >
        Trust Wallet Instructions<br></br>
        Contract Address: 0x7c52e19e4d350189bcdfb7f7a0868117e9f195e5
      </BaseText>
      <Spacer height="20px" />
      <HorizontalFlexContainer justify="center">
        <VerticalFlexContainer align="center">
            {/* <Iframe src="https://youtu.be/7oDPkxX_0b8" 
            width={windowDimensions.width-100}
            height="900px"
            frameborder="no" allowtransparency="true" allowfullscreen="true"
            overflow="auto">
            </Iframe> */}
            <iframe width="560" height="315" 
            src="https://www.youtube.com/embed/PNPhHK8g3Hc?version=3&autoplay=1&playlist=PNPhHK8g3Hc&loop=1" 
            title="YouTube video player" frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen className="videoplayer">
            </iframe>
        </VerticalFlexContainer>
        </HorizontalFlexContainer>
        <Spacer backgroundColor="transparent" height="100px" />
    </MainSectionContainer>
    );
}